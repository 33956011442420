/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ReactComponent as LinkedInIcon } from './linkedin.svg'

function App() {
  return (
    <div className="App">
      <img alt='Lone Eagle Peak' tw='absolute object-cover -z-10 h-full w-full object-left-top'
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
        lone_eagle_esracg_c_scale,w_375.png 375w,
        lone_eagle_esracg_c_scale,w_768.png 768w,
        lone_eagle_esracg_c_scale,w_1038.png 1038w,
        lone_eagle_esracg_c_scale,w_1268.png 1268w,
        lone_eagle_esracg_c_scale,w_1459.png 1459w,
        lone_eagle_esracg_c_scale,w_1611.png 1611w,
        lone_eagle_esracg_c_scale,w_1759.png 1759w,
        lone_eagle_esracg_c_scale,w_1891.png 1891w,
        lone_eagle_esracg_c_scale,w_2010.png 2010w,
        lone_eagle_esracg_c_scale,w_2237.png 2237w,
        lone_eagle_esracg_c_scale,w_2347.png 2347w,
        lone_eagle_esracg_c_scale,w_3840.png 3840w"
        src="lone_eagle_esracg_c_scale,w_3840.png"
      />
      <div
      css={
        {
          color: 'rgb(72, 79, 82)',
          paddingTop: '10%',
          marginLeft: '5%'
        }}>
          <div tw='text-7xl'>Chris Retzlaff</div>
          <a href='https://www.linkedin.com/in/chris-retzlaff' tw='inline-block ml-4 mt-4'>
            <LinkedInIcon tw='w-9 h-9' title='LinkedIn Profile'/>
          </a>
      </div>
    </div>
  );
}

export default App;
